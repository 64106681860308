import { BdsThemeProvider } from 'blip-ds/dist/blip-ds-react/components';
import { docusignSdk } from 'configurations/Environment';
import { Logger } from 'infra/adapters';
import moment from 'moment';
import 'moment/locale/pt-br';
import { AuthProvider, initUserManager } from 'oidc-react';
import { ActiveMessages } from 'pages/ActiveMessages/ActiveMessages';
import { HealthChecker } from 'pages/Application/healthChecker';
import { ChannelsContainer } from 'pages/Channels/ChannelsContainer';
import AttendanceQueue from 'pages/Configurations/AttendanceQueue/AttendanceQueue';
import AutomaticResponsesMenu from 'pages/Configurations/AutomaticResponsesMenu/AutomaticResponsesMenu';
import Configurations from 'pages/Configurations/Configurations';
import Greeting from 'pages/Configurations/Greeting/Greeting';
import { Members } from 'pages/Configurations/Members/Members';
import OpeningHours from 'pages/Configurations/OpeningHours/OpeningHours';
import Contacts from 'pages/Contacts/Contacts';
import Desk from 'pages/Desk/Desk';
import WhatsAppActivation from 'pages/ExperienceCluster/WhatsAppActivation';
import { ConnectionChoice } from 'pages/ExperienceCluster/subPages/ConnectionChoice/ConnectionChoice';
import { MetaConnection } from 'pages/ExperienceCluster/subPages/MetaConnection/MetaConnection';
import NumberPreparation from 'pages/ExperienceCluster/subPages/NumberPreparation/NumberPreparation';
import { PaywallV2 } from 'pages/ExperienceCluster/subPages/PaywallV2/PaywallV2';
import { Signature } from 'pages/ExperienceCluster/subPages/Signature/Signature';
import { SuccessfulSolicitation } from 'pages/ExperienceCluster/subPages/SuccessfulSolicitation/SuccessfulSolicitation';
import DeskAddons from 'pages/Extensions/DeskAddons/DeskAddons';
import DeskMobileApp from 'pages/Extensions/DeskMobileApp/DeskMobileApp';
import ExtensionList from 'pages/Extensions/ExtensionList/ExtensionList';
import Extensions from 'pages/Extensions/Extensions';
import Home from 'pages/Home/Home';
import BindFacebookToInstagram from 'pages/InstagramActivation/BindFacebookToInstagram/BindFacebookToInstagram';
import BindPagesChecklist from 'pages/InstagramActivation/BindPagesChecklist/BindPagesChecklist';
import BusinessAccounts from 'pages/InstagramActivation/BusinessAccounts/BusinessAccounts';
import FacebookConnection from 'pages/InstagramActivation/FacebookConnection/FacebookConnection';
import InstagramAccount from 'pages/InstagramActivation/InstagramAccount/InstagramAccount';
import InstagramActivation from 'pages/InstagramActivation/InstagramActivation';
import MessagesAccessChecklist from 'pages/InstagramActivation/MessagesAccessChecklist/MessagesAccessChecklist';
import { Onboarding } from 'pages/Onboarding/Onboarding';
import PlanDetails from 'pages/PlanDetails/PlanDetails';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getUTMParametersFromLocation } from 'utils/queryParams';
import { getOidcBaseConfig } from './constants/Oidc';
import {
  ACTIVE_MESSAGES,
  ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE,
  CHANNELS_ROUTE,
  CONFIGURATIONS_ROUTE,
  CONNECTION_CHOICE_ROUTE,
  CONTACTS_ROUTE,
  DEFAULT_ROUTE,
  DESK_ROUTE,
  EXTENSIONS_ADDONS_ROUTE,
  EXTENSIONS_BLIPCHAT_ROUTE,
  EXTENSIONS_ROUTE,
  GREETING_CONFIGURATIONS_ROUTE,
  HOME_ROUTE,
  INSTAGRAM_ACTIVATION,
  INSTAGRAM_ACTIVATION_ACTIVATE,
  INSTAGRAM_ACTIVATION_BIND_CHECKLIST,
  INSTAGRAM_ACTIVATION_BIND_PAGES,
  INSTAGRAM_ACTIVATION_BUSINESS_ACCOUNTS,
  INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION,
  INSTAGRAM_ACTIVATION_MESSAGES_ACCESS,
  MEMBERS_CONFIGURATIONS_ROUTE,
  MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE,
  META_CONNECTION_ROUTE,
  NUMBER_PREPARATION,
  ONBOARDING_ROUTE,
  OPENING_HOURS_CONFIGURATIONS_ROUTE,
  PAYWALL_ROUTE,
  PERMISSIONS_CONFIGURATIONS_ROUTE,
  PLAN_DETAILS_ROUTE,
  SIGNATURE_ROUTE,
  SUCCESSFUL_ACTIVATION_CHOICE_ROUTE,
  WPP_ACTIVATION,
} from './constants/Routes';
import MainRoute from './pages/MainRoute/MainRoute';
import { Permissions } from 'pages/Configurations/Permissions/Permissions';

const className = 'App';

const HealthCheck: React.FC = () => {
  const routesWithAuthProvider = () => (
    <BrowserRouter>
      <Routes>
        <Route path={'/healthCheck'} element={<HealthChecker />} />
      </Routes>
    </BrowserRouter>
  );

  return routesWithAuthProvider();
};

const App: React.FC = () => {
  const sourceUtm = getUTMParametersFromLocation();

  const URL_REDIRECT = window.location.origin + DEFAULT_ROUTE;
  const OIDC_CONFIG = getOidcBaseConfig(URL_REDIRECT);
  const methodName = 'App';

  useEffect(() => {
    Logger.Info('application starting main container', { methodName, className });
    moment.locale('pt-br');
    insertDocusignSdk();
  }, [methodName]);

  const insertDocusignSdk = () => {
    const script = document.createElement('script');
    script.src = docusignSdk ?? '';
    script.async = true;

    document.body.appendChild(script);
  };

  const routesWithAuthProvider = () => (
    <AuthProvider userManager={initUserManager({ ...OIDC_CONFIG })}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainRoute />}>
            <Route path={HOME_ROUTE} element={<Home />} />
            <Route path={DESK_ROUTE} element={<Desk />} />
            <Route path={ACTIVE_MESSAGES} element={<ActiveMessages />} />
            <Route path={CHANNELS_ROUTE} element={<ChannelsContainer />} />
            <Route path={CONTACTS_ROUTE} element={<Contacts />} />
            <Route path={PLAN_DETAILS_ROUTE} element={<PlanDetails />} />
            <Route path={ONBOARDING_ROUTE} element={<Onboarding />} />
            <Route path={EXTENSIONS_ROUTE} element={<Extensions />}>
              <Route index element={<ExtensionList />} />
              <Route path={EXTENSIONS_ADDONS_ROUTE} element={<DeskAddons />} />
              <Route path={EXTENSIONS_BLIPCHAT_ROUTE} element={<DeskMobileApp />} />
            </Route>
            <Route path={INSTAGRAM_ACTIVATION} element={<InstagramActivation />}>
              <Route path={INSTAGRAM_ACTIVATION_FACEBOOK_CONNECTION} element={<FacebookConnection />} />
              <Route path={INSTAGRAM_ACTIVATION_BUSINESS_ACCOUNTS} element={<BusinessAccounts />} />
              <Route path={INSTAGRAM_ACTIVATION_ACTIVATE} element={<InstagramAccount />} />
              <Route path={INSTAGRAM_ACTIVATION_BIND_PAGES} element={<BindFacebookToInstagram />} />
              <Route path={INSTAGRAM_ACTIVATION_MESSAGES_ACCESS} element={<MessagesAccessChecklist />} />
              <Route path={INSTAGRAM_ACTIVATION_BIND_CHECKLIST} element={<BindPagesChecklist />} />
            </Route>
            <Route path={WPP_ACTIVATION} element={<WhatsAppActivation />}>
              <Route path={NUMBER_PREPARATION} element={<NumberPreparation />} />
              <Route path={META_CONNECTION_ROUTE} element={<MetaConnection />} />
              <Route path={SIGNATURE_ROUTE} element={<Signature />} />
              <Route path={PAYWALL_ROUTE} element={<PaywallV2 />} />
              <Route path={CONNECTION_CHOICE_ROUTE} element={<ConnectionChoice />} />
              <Route path={SUCCESSFUL_ACTIVATION_CHOICE_ROUTE} element={<SuccessfulSolicitation />} />
            </Route>
            <Route path={CONFIGURATIONS_ROUTE} element={<Configurations />}>
              <Route path={GREETING_CONFIGURATIONS_ROUTE} element={<Greeting />} />
              <Route path={MEMBERS_CONFIGURATIONS_ROUTE} element={<Members />} />
              <Route path={PERMISSIONS_CONFIGURATIONS_ROUTE} element={<Permissions />} />
              <Route path={ATTENDANCE_QUEUES_CONFIGURATIONS_ROUTE} element={<AttendanceQueue />} />
              <Route path={MENU_AUTOMATIC_RESPONSES_CONFIGURATIONS_ROUTE} element={<AutomaticResponsesMenu />} />
              <Route path={OPENING_HOURS_CONFIGURATIONS_ROUTE} element={<OpeningHours />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );

  return <BdsThemeProvider theme="light">{routesWithAuthProvider()}</BdsThemeProvider>;
};

export default App;
export { HealthCheck };
