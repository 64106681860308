import {
  BdsGrid,
  BdsLoadingSpinner,
  BdsTable,
  BdsTableBody,
  BdsTableHeader,
  BdsTableRow,
  BdsTableTh,
  BdsTypo,
} from 'blip-ds/dist/blip-ds-react';
import LayoutContainer from 'components/LayoutContainer/LayoutContainer';
import MainLayout from 'components/MainLayout/MainLayout';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { IInstallationState } from 'redux/slices/InstallationSlice/interface';
import { useInstallation } from 'redux/slices/InstallationSlice/installationSlice';
import { useEffect, useState } from 'react';
import { getMessagesTemplates } from 'services/BlipGoApiService';
import { Template } from 'objects/types/MessageTemplate';
import { TemplateListItem } from '../../components/TemplateListItem/TemplateListItem';
import { ApplicationDetail } from 'configurations/Environment';
import { Alert } from 'components/Alert/Alert';
import { Analytics } from 'infra/adapters';
import ExternalUrls from 'constants/ExternalUrls';
import { createToastError } from 'services/Toats';
import { TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE } from 'constants/ToastMessages';

export const ActiveMessagesProView = () => {
  const { tenant, router } = useSelector<RootState, IInstallationState>(useInstallation);
  const [messageTemplates, setMessageTemplates] = useState<Template[]>();
  const [openRedirectConfirmation, setOpenRedirectConfirmation] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const sendActiveMessagesUrl = `https://${tenant.id}.${ApplicationDetail}/${router.shortName}/growth/activemessages/sendactivemessage`;

  useEffect(() => {
    loadTemplates();
  }, []);

  const loadTemplates = async () => {
    try {
      setIsLoading(true);
      const res = await getMessagesTemplates(tenant.id, router.shortName);
      setMessageTemplates(res);
    } catch {
      createToastError(TOAST_FAILURE, TOAST_GET_FAILURE_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTemplateClick = (template: Template) => {
    setSelectedTemplate(template);
    setOpenRedirectConfirmation(true);
  };

  const handleRedirectConfirm = () => {
    window.open(sendActiveMessagesUrl, '_blank');
    Analytics.Track(Analytics.events.ACTIVE_MESSAGES_SEND);
    setOpenRedirectConfirmation(false);
  };

  return (
    <MainLayout>
      <LayoutContainer>
        <Alert
          open={openRedirectConfirmation}
          title={`Usar o modelo ${selectedTemplate?.templateId}?`}
          description="Você será direcionado para a plataforma Blip para selecionar a audiência da sua mensagem."
          icon="info"
          variant="system"
          onCancel={() => setOpenRedirectConfirmation(false)}
          onConfirm={handleRedirectConfirm}
        />
        {isLoading ? (
          <BdsGrid justifyContent="center" alignItems="center" height="100%">
            <BdsLoadingSpinner />
          </BdsGrid>
        ) : (
          <BdsGrid
            direction="column"
            gap="2"
            lg="6"
            md="8"
            padding="y-4"
            height="calc(100vh - 4rem)"
            style={{ margin: 'auto' }}
          >
            <BdsTypo variant="fs-20" bold="bold" margin={false}>
              Comece selecionando um modelo da mensagem pré-aprovada
            </BdsTypo>
            <BdsTypo>
              Após escolher, você será redirecionado para a plataforma Blip para os próximos passos.{' '}
              <a href={ExternalUrls.activeMessageHelp} target="_blank" rel="noreferrer">
                Entenda como funciona o disparo de mensagens em massa.
              </a>
            </BdsTypo>
            <BdsTable style={{ backgroundColor: '#FFF', overflowY: 'auto', margin: '0.5rem 0' }}>
              <BdsTableHeader>
                <BdsTableRow>
                  <BdsTableTh>Nome</BdsTableTh>
                  <BdsTableTh>Mensagem</BdsTableTh>
                  <BdsTableTh></BdsTableTh>
                </BdsTableRow>
              </BdsTableHeader>
              <BdsTableBody>
                {messageTemplates?.map((template, index) => {
                  return (
                    <TemplateListItem
                      key={index}
                      id={template.templateId}
                      text={template.body?.text}
                      onTemplateClick={() => handleTemplateClick(template)}
                    />
                  );
                })}
              </BdsTableBody>
            </BdsTable>
          </BdsGrid>
        )}
      </LayoutContainer>
    </MainLayout>
  );
};
