import { BdsButton, BdsTableCell, BdsTableRow, BdsTypo } from 'blip-ds/dist/blip-ds-react';

type ITemplateListItemProps = {
  id: string;
  text: string;
  onTemplateClick: () => void;
};

export const TemplateListItem = ({ id, text, onTemplateClick }: ITemplateListItemProps) => {
  return (
    <BdsTableRow>
      <BdsTableCell>
        <BdsTypo variant="fs-14" bold={'semi-bold'}>
          {id}
        </BdsTypo>
      </BdsTableCell>
      <BdsTableCell>
        <BdsTypo variant="fs-14" bold={'semi-bold'}>
          {text}
        </BdsTypo>
      </BdsTableCell>

      <BdsTableCell type="custom">
        <BdsButton variant="tertiary" onBdsClick={onTemplateClick} data-testid={`template-${id}`}>
          Usar modelo
        </BdsButton>
      </BdsTableCell>
    </BdsTableRow>
  );
};
