import { BdsAlert, BdsAlertActions, BdsAlertBody, BdsAlertHeader, BdsButton } from 'blip-ds/dist/blip-ds-react';

type IAlert = {
  open: boolean;
  title: string;
  description: string;
  icon: string;
  variant: 'system' | 'error' | 'warning' | 'delete';
  onCancel: () => void;
  onConfirm: () => void;
};
export const Alert = ({ open, title, description, icon, variant, onCancel, onConfirm }: IAlert) => {
  return (
    <BdsAlert open={open}>
      <BdsAlertHeader variant={variant} icon={icon}>
        {title}
      </BdsAlertHeader>
      <BdsAlertBody>{description}</BdsAlertBody>
      <BdsAlertActions>
        <BdsButton variant="secondary" onBdsClick={onCancel}>
          Voltar
        </BdsButton>
        <BdsButton variant="secondary" onBdsClick={onConfirm}>
          Confirmar
        </BdsButton>
      </BdsAlertActions>
    </BdsAlert>
  );
};
