import { useSelector } from 'react-redux';
import { useProject } from 'redux/slices/ProjectSlice/projectSlice';
import { PlanTypeEnum } from 'objects/types/BlipGoApi';
import { ActiveMessagesProView } from './views/ActiveMessagesProView/ActiveMessagesProView';
import { ActiveMessagesFreeView } from './views/ActiveMessagesFreeView/ActiveMessagesFreeView';

export const ActiveMessages = () => {
  const { planType } = useSelector(useProject);

  return <>{planType === PlanTypeEnum.Pro ? <ActiveMessagesProView /> : <ActiveMessagesFreeView />}</>;
};
